<template>
  <div class="col school_dashboard_page_wrapper" v-loading="loadingValue">
    <card>
      <div class="some_details">
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title"> الفصول </span>
          <span class="d-block w-100">{{ cardsData.count_of_groups }}</span>
        </div>
        <div class="every_part shadow-sm">
          <i
            class="fa fa-exclamation icon not_active"
            v-if="cardsData.in_active_classes != 0"
          ></i>
          <i
            class="fa fa-check icon active"
            v-if="cardsData.in_active_classes == 0"
          ></i>
          <span class="d-block w-100 title">الفصول الغير فعالة</span>
          <span class="d-block w-100">{{ cardsData.in_active_classes }}</span>
        </div>
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title"> المقاعد المستخدمة </span>
          <span class="d-block w-100"
            >{{ cardsData.max_seats }} / {{ cardsData.count_of_students }}</span
          >
        </div>
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title"> المدرسين </span>
          <span class="d-block w-100">{{ cardsData.count_of_teachers }}</span>
        </div>
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title"> الطلاب </span>
          <span class="d-block w-100">{{ cardsData.count_of_students }}</span>
        </div>
        <div class="every_part shadow-sm">
          <i class="fa fa-check icon active"></i>
          <span class="d-block w-100 title"> الدروس المكتملة</span>
          <span class="d-block w-100">{{
            cardsData.avg_completed_lessons
          }}</span>
        </div>
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title"> الأنشطة </span>
          <span class="d-block w-100">{{ cardsData.count_of_activities }}</span>
        </div>
        <div class="every_part shadow-sm">
          <i
            class="fa fa-exclamation icon not_active"
            v-if="cardsData.pending_activities != 0"
          ></i>
          <i
            class="fa fa-check icon active"
            v-if="cardsData.pending_activities == 0"
          ></i>
          <span class="d-block w-100 title"> الأنشطة الغير فعالة</span>
          <span class="d-block w-100">{{ cardsData.pending_activities }}</span>
        </div>
      </div>
    </card>
    <!-- Start Charts  -->
    <card>
      <div class="row">
        <div class="col-md-6 mb-4 mt-4">
          <span class="d-block chart_title">المستويات</span>
          <div class="chart_container">
            <apexchart
              height="350"
              type="pie"
              width="450"
              :options="chartTwoForLevels.chartOptions"
              :series="chartTwoForLevels.series"
            ></apexchart>
          </div>
        </div>
        <div class="col-md-6 mb-4 mt-4">
          <span class="d-block chart_title">الطلاب</span>
          <div class="chart_container">
            <apexchart
              height="350"
              type="pie"
              width="400"
              :options="chartTwoForGenders.chartOptions"
              :series="chartTwoForGenders.series"
            ></apexchart>
          </div>
        </div>
      </div>
    </card>
    <!-- Start Charts  -->

    <!-- Start Details  -->
    <server-table
      :DataItems="schoolAdminReport"
      :count="schoolAdminReportCount"
      :useFilter="false"
      :enable_export="false"
      :action="false"
      @Refresh="Refresh"
    >
      <template #columns>
        <el-table-column prop="group_name" label="الفصل" min-width="90">
          <template slot-scope="scope">
            <span class="">
              {{ scope.row.group_name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('Global.status')">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 0"
              round
              size="small"
              type="danger"
            >
              {{ $t("non-active") }}
            </el-button>
            <el-button
              v-if="scope.row.status == 1"
              round
              size="small"
              type="primary"
            >
              {{ $t("active") }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="teacher_name" label="المدرس" min-width="110">
          <template slot-scope="scope">
            <span>
              {{
                scope.row.teacher_name != null ? scope.row.teacher_name : "__"
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="students_count" label="عدد الطلاب">
        </el-table-column>
        <el-table-column prop="grade" label="المستوي">
          <template slot-scope="scope">
            <span>
              {{ scope.row.grade ? `Grade ${scope.row.grade}` : "__" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="avg_completed_lessons" label="الدروس المكتملة">
        </el-table-column>
        <el-table-column
          min-width="90px"
          prop="avg_completed_activities"
          label="الأنشطة المكتملة"
        >
        </el-table-column>
        <el-table-column prop="count_of_pre_exam_one_students" label="قبلي 1">
        </el-table-column>
        <el-table-column prop="count_of_pre_exam_two_students" label="قبلي 2">
        </el-table-column>
        <el-table-column prop="count_of_last_exam_one_students" label="بعدي 1">
        </el-table-column>
        <el-table-column prop="count_of_last_exam_two_students" label="بعدي 2">
        </el-table-column>
      </template>
    </server-table>
    <!-- End Details  -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import { mapGetters } from "vuex";

export default {
  components: { ServerTable },
  data() {
    return {
      chartOne: {
        series: [44, 67, 30, 58],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          legend: {
            position: "bottom",
          },
          dataLabels: {
            enabled: true,
          },
          labels: ["Grade 1", "Grade 2", "Grade 3", "Grade 4"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      chartTwo: {
        series: [65, 35],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          legend: {
            position: "bottom",
          },
          dataLabels: {
            enabled: true,
          },
          labels: ["Male", "Female"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      cardsData: {
        count_of_groups: null,
        in_active_classes: null,
        max_seats: null,
        count_of_teachers: null,
        count_of_students: null,
        avg_completed_lessons: null,
        count_of_activities: null,
        pending_activities: null,
      },
    };
  },
  mounted() {
    this.Refresh();
  },

  computed: {
    ...mapGetters({
      loadingValue: ["schools/load"],
      schoolAdminReport: ["schools/schoolAdminDetailsReport"],
      schoolAdminReportCount: ["schools/schoolAdminDetailsReportCount"],
      schoolAdminReportLevelsChart: [
        "schools/schoolAdminDetailsReportLevelsChart",
      ],
      schoolAdminReportGendersChart: [
        "schools/schoolAdminDetailsReportGendersChart",
      ],
    }),
    offset() {
      if (this.$route.query.currentPage && this.$route.query.perPage) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },

    limit() {
      if (this.$route.query.perPage) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
    chartTwoForGenders() {
      let series = [];
      if (this.schoolAdminReportGendersChart.length > 0) {
        this.schoolAdminReportGendersChart.forEach((element) => {
          series.push(element.user_count);
        });
      }
      return {
        series: series,
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          legend: {
            position: "bottom",
          },
          dataLabels: {
            enabled: true,
          },
          labels: ["Male", "Female"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };
    },
    chartTwoForLevels() {
      let series = [];
      let labels = [];
      if (this.schoolAdminReportLevelsChart.length > 0) {
        this.schoolAdminReportLevelsChart.forEach((element) => {
          series.push(element.user_count);
          labels.push(`Grade ${element.grade}`);
        });
      }
      return {
        series: series,
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          legend: {
            position: "bottom",
          },
          dataLabels: {
            enabled: true,
          },
          labels: labels,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };
    },
  },
  methods: {
    Refresh(
      query = {
        offset: this.offset,
        limit: this.limit,
      }
    ) {
      this.$store
        .dispatch("schools/handlerGetRegionAdminSchoolsReport", {
          id: this.$route.params.school_id,
          query: query,
        })
        .then((res) => {
          this.cardsData.count_of_groups = res.count_of_groups;
          this.cardsData.in_active_classes = res.in_active_classes;
          this.cardsData.max_seats = res.max_seats;
          this.cardsData.count_of_teachers = res.count_of_teachers;
          this.cardsData.count_of_students = res.count_of_students;
          this.cardsData.avg_completed_lessons = res.avg_completed_lessons;
          this.cardsData.count_of_activities = res.count_of_activities;
          this.cardsData.pending_activities = res.pending_activities;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.sharing_wrapper {
  width: fit-content;
  cursor: pointer;
  color: #fff;

  display: flex;
  background: #409eff;
  padding: 8px 10px;
  border-radius: 8px;
  gap: 5px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
  .print_now {
    font-size: 22px;
  }
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
.display_when_print {
  display: none;
}
@media print {
  // .expand-panel,
  .sharing_wrapper,
  .hide_when_print {
    display: none;
  }
  .display_when_print {
    display: block;
  }
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
.chart_title {
  font-weight: bold;
  color: #555;
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
}
.chart_container {
  display: flex;
  justify-content: center;
}
.school_dashboard_page_wrapper {
  .some_details {
    display: flex;
    gap: 1%;
    flex-wrap: wrap;
    padding: 15px;
    .every_part {
      width: calc(96% / 4);
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      min-height: 150px;
      transition: 0.5s;
      border-radius: 8px;
      margin-bottom: 25px;
      &:hover {
        background-color: #eee;
      }
      .title {
        font-weight: bold;
        color: #555;
        font-size: 17px;
      }
      .icon {
        position: absolute;
        top: 10px;

        color: #fff;
        border-radius: 50%;
        right: 10px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        font-size: 14px;
        padding: 4px;
        &.not_active {
          background-color: #fb404b;
        }
        &.active {
          background-color: #67c23a;
        }
      }
    }
  }
}
</style>
